import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import Faq from '../../Faq'
import { LanguageContext } from '../../../context/LanguageContext'
import { Paragraph__Faq } from '../../../types/generated'

export const ParagraphFaqFields = graphql`
  fragment ParagraphFaqFields on paragraph__faq {
    __typename
    id
    langcode
    relationships {
      field_question_cards {
        id
        langcode
        field_question
        field_answer
      }
    }
  }
`

const ParagraphFaq = (props: Paragraph__Faq) => {
  const { t } = useContext(LanguageContext)
  const { relationships } = props

  return (
    <section>
      <div className="max-w-7xl mx-auto pt-7 pb-14">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {t('Frequently asked questions')}
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {relationships?.field_question_cards?.map((faq) =>
              faq ? <Faq faq={faq} key={faq.id} /> : null,
            )}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default ParagraphFaq
