import React from 'react'
import { Disclosure } from '@headlessui/react'
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon'
import { Paragraph__Faq_Card } from '../../types/generated'

interface Props {
  faq: Paragraph__Faq_Card
}

const Faq = ({ faq }: Props) => {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Disclosure as="div" className="pt-6">
      {({ open }) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
              <span className="font-medium text-gray-900">{faq?.field_question}</span>
              <span className="ml-6 h-7 flex items-center">
                <ChevronDownIcon
                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <p className="text-base text-gray-500">{faq?.field_answer}</p>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Faq
